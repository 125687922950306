@import "../bootstrap-pg/bootstrap";
@import "variables";

html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'Lato', Arial, Helvetica, 'Trebuchet MS', sans-serif;
    margin-bottom: 142px;
}

h1,
h2,
h3,
h4 {
    font-family: 'LatoHeavy', Arial, Helvetica, 'Trebuchet MS', sans-serif;
}

a {
    color: inherit;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 142px;
    background-color: $pg-dark-gray;
    color: $pg-light-gray;

    #footer-logo-block {
        margin-top: 30px;
        img {
            width: 180px;
        }
    }

    #footer-payment-block {
        margin-top: 10px;
    }
}

.navbar {
    .navbar-brand {
        padding: 5px 5px;
    }

    #brand-logo {
        width: 160px;
    }
    h1 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.collage {
    display: none;
    padding: 0px;

    img {
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: bottom;
        opacity: 1;
        border-collapse: collapse;
    }
}

.image-wrapper {
    a {
        display: inline-block;
        &:hover {
            background-color: red;
            img {
                opacity: 0.5;
            }
            .gallery-image-overlay {
                display: block;
            }
        }
    }
}

.gallery-image-overlay {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    padding: 10px;
    display: none;

    h2 {
        text-transform: uppercase;
        font-size: 1.2em;
        font-weight: bold;
        margin: 5px;
    }
    .plus {
        font-size: 96px;
        line-height: 64px;
    }
    .outer {
        width: 100%;
        height: 100%;
        border: solid 2px #fff;
    }
    .inner {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
}

#left-menu {
    width: 80%;
    padding: 0px;
    margin: auto;
    margin-top: 50%;
    height: 500px;
}

ul.gallery-menu {
    li {

        list-style: none;
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 1.2em;
        text-transform: uppercase;
        text-align: center;

        &.selected a {
            color: $brand-primary;
        }
    }
}

#left-column {
    position: fixed;
}

#right-column {
    position: absolute;
    top: 0px;
}

#gallery-brand-logo {
    width: 80%;
    margin-top: 50px;
}

.footer-links {
    padding-top: 15px;
}

.public-page {
    color: white;

    &:hover {
        color: white;
    }
    &:focus {
        color: white;
    }
}

#publicPage {
    .modal-body {
        padding: 0px 15px;
    }
    .modal-dialog {
        min-width: 40%;
    }
}

#full-menu {
    width: 100%;
    height: 100%;
    background-color: $body-bg;
    position: absolute;
    z-index: 1010;
    margin-left: -34px;
    opacity: 0.9;
}
